<template>
    
    <div class="dropbox">
        <b-form-file
        multiple
            v-model="Data.images"
            class="input-file"
            accept=".jpg, .png, .gif"
            placeholder="Choose file"
            @input="onFileInputChange(portfolioData.id)"
        />
     
        <draggable
          :list="files"
          tag="ul"
          group="people"
          @change="undateImage"
          class="list-group list-group-flush cursor-move"
        >
        <b-list-group-item
            v-for="(listItem, index) in files"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
             
              <div class="ml-25">
                    <div class="remove-image" @click="removeImageConfirm(listItem.id)"><feather-icon icon="XCircleIcon" size="20" vari /></div>
                    <b-img :src="listItem.src"
                    
                    fluid
                    class="rounded mr-2 mb-1 mb-md-0" />
                    <div v-if="listItem.loading" class="overlay">
                        <b-spinner
                            style="width: 3rem; height: 3rem;"
                            class="mr-1"
                            label="Large Spinner"
                        />
                    </div>
                    <div v-if="listItem.err" class="overlay">
                        <feather-icon icon="AlertCircleIcon"
                           size="50"
                            class="mr-1"
                            
                        />
                    </div>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
        
        <p >
            Drag your file(s) here to begin<br> or click to browse
        </p>
        
       
    </div>
</template>
<script>
import draggable from 'vuedraggable'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
    BFormFile,
    BCardText,
    BListGroupItem,
    BImg,
    BSpinner,
 } from 'bootstrap-vue'

import { ref, watch, computed } from '@vue/composition-api'
export default {
    components:{
        BFormFile,
        BCardText,
        BListGroupItem,
        BImg,
        draggable,
        BSpinner
    },
    props: {
    
     portfolioData: {
       type: Object,
       required: false,
     },
   },

   created(){
   // console.log('portfolioData',portfolioData);
   },
    data(){
        return {
            images: [
            
            ],
        }

    },
    computed: {
      
    },
   
    setup(props){
        const files = ref([]);
        console.log('portfolioData',props.portfolioData)
      
        const blankData = {
            images:[]
        }
        //fetchImages
        store
          .dispatch('app-portfolio/fetchImages', {
            portfolio_id: props.portfolioData.id,
            order_by:'ordering_count ASC',
          
            
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  images  = response.data.data
            files.value = images;
         
          
          })
          .catch(() => {})
        const Data = ref(JSON.parse(JSON.stringify(blankData)));
        const listfiles = ref([]);
        const undateImage = ()=>{
         
            store.dispatch('app-portfolio/updateOrdering',files.value)
            
            
        }
        const removeImage = async (id)=>{
            store.dispatch('app-portfolio/fetchImageDelete',id).then(response=>{
                var ind = files.value.findIndex(p => p.id == id);
                files.value.splice(ind, 1); 

            })
    
  
            
        }
        const onFileInputChange = async (id)=>{
           
            let images = Data.value.images;
            const userStorage = JSON.parse(localStorage.getItem('userData'));
            const reader = new FileReader();
          

            let sm = images.map((ele,index)=>{
                        const  file = ele;
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                            let base64 =  reader.result;
                            let img  = {
                                src:base64,
                                loading:true,
                                index:index,
                                err:false,
                            }
                            files.value.push(img);
                         
                        }, false);
                        reader.readAsDataURL(file)
                        let formData = new FormData();
                      
                        formData.append('images', ele);
                        formData.append('path', '/var/www/html/uploadfiles/uploads/smk/portfolio/');
                        formData.append('savepath', 'uploads/smk/portfolio/');
                       
                        formData.append('created_by', userStorage.id);
                        store.dispatch('app-portfolio/uploadimages', {formData:formData,id:id})
                        .then((res) => {
                            var ind = files.value.findIndex(p => p.index == index);
                            if(res.data.success){
                                let newimg  = res.data.data;
                                
                                
                               
                                files.value.splice(ind, 1); 
                                files.value.push(newimg);
                                
                            }else{
                                let newimg  = {
                                    src:files.value[ind].src,
                                    loading:false,
                                    err:true,
                                   
                                }
                               
                                files.value.splice(ind, 1); 
                                files.value.push(newimg);
                            }
                           
                          
                            //console.log('res',res);
                        });
                       
                        return ele.name; 
                
                    });
                   
                  
           
        }
        return {
            onFileInputChange,
            files,
            listfiles,
            Data,
            undateImage,
            removeImage

        }
    },
    computed:{
        hasFiles() {
        }

    },
    methods:{
        removeImageConfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Portfolio Image ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                this.removeImage(id)
                }
            
                //this.deleteData(id)
                })

        }
        
    }
    
}
</script>
<style lang="scss">
  
  .custom-file-label{
    cursor: pointer;
    
  }
  
  
  
  


</style>
