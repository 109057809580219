import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function usePortfolioList() {
    const isPortfolioFormSidebarActive = ref(false);
    const catsData = ref([]);
    const toast = useToast();
    const refPortfolioListTable = ref(null);
    const tableColumns = [
      { key: 'cover_image', label: 'Cover Image', sortable: false, tdClass:'width-200 p-1' },
      
        { key: 'title', label: 'Title', sortable: false },
        { key: 'slug', label: 'Slug', sortable: false },
        { key: 'category_name', label: 'Category Name', sortable: false },
        { key: 'is_active', label: 'Status', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ];
    const portfolioData = ref({});
    const perPage = ref(10)
    const totalPortfolios = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const catFilter = ref(null);
    const dataMeta = computed(() => {
        const localItemsCount = refPortfolioListTable.value ? refPortfolioListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPortfolios.value,
        }
    })
    store.dispatch('app-portfolio/fetchCats', {})
    .then(response => {
      catsData.value = response.data.data
    })
    const refetchData = () => {
        refPortfolioListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter,catFilter], () => {
        refetchData()
    })
    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
        console.log('offset',offset);
        store
          .dispatch('app-portfolio/fetchData', {
            limit: perPage.value,
            offset:offset,
            keywords: searchQuery.value,

            category_id:catFilter.value
            
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  groups  = response.data.data
         
            const total = response.data.total;
            callback(groups)
            totalPortfolios.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const updatePortfolio = (data)=>{

      portfolioData.value = data;
      isPortfolioFormSidebarActive.value = true;
    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-portfolio/deletePortfolio',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete User",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    const resolvePortfolioStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
    }
    const resolvePortfolioStatusnameVariant = status => {
        console.log('status',status);
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }
    return {
        isPortfolioFormSidebarActive,
        fetchData,
        tableColumns,
        perPage,
        currentPage,
        totalPortfolios,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPortfolioListTable,
    
        statusFilter,
        catFilter,
        deleteData,

        portfolioData,
        catFilter,
        catsData,
        updatePortfolio,
        refetchData,
        resolvePortfolioStatusVariant,
        resolvePortfolioStatusnameVariant
    }
}