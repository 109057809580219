import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCats(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/portfolios/v1/category/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        addCategory(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/portfolios/v1/category/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateCategory(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/portfolios/v1/category/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteCategory(ctx,id){
          
            
            return new Promise((resolve, reject) => {
              axios
                .delete('/portfolios/v1/category/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
       
        fetchData(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/portfolios/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addPortfolio(ctx,queryParams){
          //var data = JSON.stringify(queryParams);
          
          return new Promise((resolve, reject) => {
            axios
              .post('/portfolios/v1/add',queryParams.formData)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      },
      updatePortfolio(ctx,queryParams){
       
         // var data = JSON.stringify(queryParams.formData);
          
          return new Promise((resolve, reject) => {
            axios
              .put('/portfolios/v1/update/'+queryParams.data.id,queryParams.formData,{
                headers:{
                  'Content-Type': 'multipart/form-data',
                  'dataType':'json'
                }
              })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      },
      deletePortfolio(ctx,id){
        
          
        return new Promise((resolve, reject) => {
          axios
            .delete('/portfolios/v1/delete/'+id)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })

    },
    fetchImages(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .post('/portfolios/v1/image/getall',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateOrdering(ctx, queryParams) {
        var data = JSON.stringify(queryParams);
        
        return new Promise((resolve, reject) => {
          axios
            .put('/portfolios/v1/image/updateOrdering',data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    
    uploadimages(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
          .post('/portfolios/v1/image/add/'+queryParams.id,queryParams.formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchImageDelete(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/portfolios/v1/image/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    }
  }
}